<template>
  <v-app>
    <v-app-bar app flat color="grey lighten-5">
      <v-toolbar-title
        ><img src="@/assets/kentech_black.png" width="270" class="logo"
      /></v-toolbar-title>
      <v-spacer></v-spacer>

      <v-toolbar-items class="hidden-xs-only">
        <v-btn text href="#me">Me</v-btn>
        <v-btn text href="#clients">Clients</v-btn>
        <v-btn text href="#contact">Contact</v-btn>
      </v-toolbar-items>
      <!-- <v-btn icon class="hidden-sm-and-up">
        <v-icon>menu</v-icon>
      </v-btn> -->
    </v-app-bar>
    <v-main>
      <router-view />
    </v-main>
    <v-footer app absolute dark color="black" class="pa-3">
      <div style="width: 100%; text-align: center; opacity: 0.4">
        &copy; {{ new Date().getFullYear() }} Kentech Software Solutions Ltd
      </div>
    </v-footer>
  </v-app>
</template>

<script>
export default {
  data() {
    return {
      title: "Vuetify.js",
    };
  },
  name: "App",
};
</script>

<style scoped>
.logo {
  position: absolute;
  opacity: 0.4;
  left: 16px;
  top: 50%;
  -webkit-transform: translate(0%, -50%);
  transform: translate(0%, -50%);
}
</style>

