<template>
  <v-container fluid class="ma-0 pa-0">
    <div class="full-bg-img">
      <div class="profileImg">
        <v-avatar
          slot="activator"
          size="100px"
          transition="slide-y-transition"
          origin="bottom center 0"
          class="avatar"
        >
          <img :src="require('@/assets/mug.jpg')" alt="Avatar" />
        </v-avatar>
        <div class="name">Ben Wilson</div>
        <div class="location">Leeds</div>

        <v-text-field
          label="Enter Password"
          v-model="desc"
          solo
          dark
          disabled
          :type="passwordType"
          class="textDescription"
        >
        </v-text-field>
        <v-icon class="passwordButton" :color="passwordButtonColor">{{
          passwordButton
        }}</v-icon>
        <v-icon large color="green darken-2"> arrow-right </v-icon>
      </div>
    </div>

    <div class="light-blue pa-5 d-flex justify-center">
      <div style="max-width: 1000px">
        <div class="titleHeading">Hi there!</div>
        <v-divider></v-divider>
        <div class="description">
          I'm a Mobile & Web Developer with a focus on solving business
          problems. Time costs money and I build time saving, task automating
          software to increase your efficiency.
        </div>
      </div>
    </div>

    <v-row class="grey lighten-4">
      <v-container class="pa-5 pb-10">
        <v-row>
          <v-col
            cols="12"
            md="6"
            v-for="(skill, index) in skills"
            :key="index"
            class="pa-5"
          >
            <v-card flat color="grey lighten-4" class="text-center">
              <v-icon class="skillIcon" size="60"> {{ skill.icon }}</v-icon>
              <div class="skillTitle">{{ skill.title }}</div>
              <div class="skillText">{{ skill.text }}</div>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-row>

    <v-container fluid class="pa-5 black">
      <v-row>
        <v-col
          cols="12"
          sm="6"
          md="4"
          pa-4
          class="logoDiv"
          v-for="(logo, index) in logos"
          :key="index"
        >
          <img class="logoImg" :src="logo.img" />
        </v-col>
      </v-row>
    </v-container>

    <div
      id="contact"
      style="background-color: black; padding: 100px 20px 20px 20px"
    >
      <v-container>
        <div class="contactTitle">Say Hi!</div>
        <div class="contactDescription">
          <a class="mail" href="mailto:ben.wilson@kentechsoftware.com"
            >ben.wilson@kentechsoftware.com</a
          >
        </div>
        <div class="address">
          <div>Platform</div>
          <div>New Station Street</div>
          <div>Leeds, LS1 4JB</div>
          <div>United Kingdom</div>
        </div>
      </v-container>
    </div>
  </v-container>
</template>

<script>
var i = 0;
var txt = "Mobile & Web Developer";
var speed = 50;
export default {
  data() {
    return {
      desc: "Enter Password",
      passwordType: "text",
      passwordButton: "arrow_right",
      passwordButtonColor: "white",
      skills: [
        {
          icon: "attachment",
          title: "RESEARCH",
          text: "Understanding how your business currently operates to find improvements is key. You may have already targeted a particular area/problem that needs addressing which we can develop",
        },
        {
          icon: "group_work",
          title: "PROBLEM SOLVING",
          text: "Once we understand what we are trying to achieve, deciding how to tackle the problem from a technical standpoint and how that integrates with your current systems and processes is required next",
        },
        {
          icon: "desktop_mac",
          title: "GET TO WORK",
          text: "Functionality comes first. We work together to achieve a solution and make sure it achieves what we intended. Sometimes it is difficult to know what you want or how something needs to work until you start",
        },
        {
          icon: "autorenew",
          title: "ITERATE",
          text: "After iterating this process several times until you are completely satisfied, we will have a fully functioning product. If things change or you need to add new features, we can always continue on the project where we left off",
        },
      ],
      logos: [
        {
          img: require("@/assets/acpi.png"),
        },
        {
          img: require("@/assets/brothers.png"),
        },
        {
          img: require("@/assets/chia.png"),
        },
        {
          img: require("@/assets/cvita.png"),
        },
        {
          img: require("@/assets/elite.png"),
        },
        {
          img: require("@/assets/gam.png"),
        },
        {
          img: require("@/assets/snapsales.png"),
        },
        {
          img: require("@/assets/sure.png"),
        },
        {
          img: require("@/assets/swanlandphysio.png"),
        },
      ],
    };
  },
  methods: {
    typeWriter() {
      if (i < txt.length) {
        this.desc += txt.charAt(i);
        i++;
        setTimeout(this.typeWriter, speed);
      } else {
        setTimeout(() => {
          this.passwordButtonColor = "green";
          this.passwordButton = "check_circle";
          this.passwordType = "text";
        }, 1500);
      }
    },
  },
  created() {
    setTimeout(() => {
      this.desc = "";
      this.passwordType = "password";
      this.typeWriter();
    }, 5000);
  },
};
</script>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.logoDiv {
  height: 200px;
  text-align: center;
  border: 0.5px solid rgba(255, 255, 255, 0.2);
  position: relative;
}

.logoImg {
  position: absolute;
  max-width: 60%;
  max-height: 60%;
  width: auto;
  height: auto;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.full-bg-img {
  background-size: cover;
  background-position: center;
  height: 72vw; /* vh = viewport height */
  min-height: 600px;
  max-height: 90vh;
  background-image: url("../assets/kentech_website_bg4.jpg");
  position: relative;
}

.avatar {
  box-shadow: 0 0 1pt 2pt white;
  border: 1pt solid white;
  -webkit-animation: 1s ease 0s normal forwards 1 fadeinimg;
  animation: 1s ease 0s normal forwards 1 fadeinimg;
}

.profileImg {
  position: absolute;
  right: 50%;
  top: 44%;
  -webkit-transform: translate(30%, -50%);
  transform: translate(50%, -50%);
  text-align: center;
  width: 250px;
}

.name {
  text-align: center;
  font-size: 20px;
  opacity: 0.8;
  color: white;
  font-weight: 500;
  font-family: "soleil", sans-serif;
  letter-spacing: 2px;
  -webkit-animation: 2s ease 0s normal forwards 1 fadein;
  animation: 2s ease 0s normal forwards 1 fadein;
  margin-top: 4px;
}

.titleHeading {
  font-weight: 200;
  font-family: "soleil", sans-serif;
  font-size: 26px;
  letter-spacing: 3px;
}

.description {
  font-weight: 400;
  /* font-family: "soleil",sans-serif; */
  /* font-size: 34px; */
  font-size: calc(1vw + 2.5vh);
  letter-spacing: 2px;
}

.location {
  text-align: center;
  font-size: 13px;
  opacity: 0.8;
  color: white;
  font-weight: 200;
  font-family: "soleil", sans-serif;
  letter-spacing: 5px;
  -webkit-animation: 2.5s ease 0s normal forwards 1 fadein;
  animation: 2.5s ease 0s normal forwards 1 fadein;
  margin-bottom: 40px;
}

.textDescription {
  text-align: center;
  -webkit-animation: 4s ease 0s normal forwards 1 fadein;
  animation: 4s ease 0s normal forwards 1 fadein;
}

.textDescription >>> input {
  text-align: center;
  font-size: 13px;
  font-weight: 400;
  font-family: "soleil", sans-serif;
  letter-spacing: 2px;
  opacity: 0.7;
  color: white;
}

.passwordButton {
  position: relative;
  top: -65px;
  right: -140px;
  -webkit-animation: 4s ease 0s normal forwards 1 fadein;
  animation: 4s ease 0s normal forwards 1 fadein;
}

.skillTitle {
  /* text-align: left; */
  font-size: 22px;
  font-weight: 500;
  font-family: "soleil", sans-serif;
  letter-spacing: 4px;
  padding-top: 20px;
  padding-bottom: 20px;
}

.skillText {
  text-align: justify;
  font-size: 16px;
  font-weight: 200;
  font-family: "soleil", sans-serif;
  letter-spacing: 2px;
}

.skillIcon {
  /* width: 100px;
    height: 100px; */
}

.contactTitle {
  text-align: center;
  font-size: 50px;
  font-weight: 800;
  font-family: "soleil", sans-serif;
  color: white;
  letter-spacing: 3px;
}

.contactDescription {
  text-align: center;
  /* font-size: 30px; */
  font-size: calc(10px + 0.3vw);
  font-weight: 200;
  font-family: "soleil", sans-serif;
  color: white;
  letter-spacing: 5px;
}

.mail {
  color: white;
  text-decoration: none;
}

a:hover {
  color: rgba(255, 255, 255, 0.5);
}

.address {
  text-align: center;
  color: white;
  font-size: calc(12px + 0.2vw);
  font-weight: 200;
  font-family: "soleil", sans-serif;
  padding-top: 100px;
}

@keyframes fadeinimg {
  0% {
    opacity: 0;
  }
  66% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadein {
  0% {
    opacity: 0;
  }
  66% {
    opacity: 0;
  }
  100% {
    opacity: 0.8;
  }
}

@-webkit-keyframes fadein {
  0% {
    opacity: 0;
  }
  66% {
    opacity: 0;
  }
  100% {
    opacity: 0.8;
  }
}
</style>
